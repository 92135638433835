//
// Header
//

header {
  position: fixed;
  width: 100%;
  z-index: 20;
  background: $white;
  height: 200px;
  transform: translateZ(0);
}

header .container {
  display: block;
}

header .logo {
  display: none !important;
}

header.fixed .logo {
  margin: 15px 0 0 40px;
  max-width: 160px;
  display: block !important;
  position: absolute;
  z-index: 6999;
}

header.fixed {
  position: fixed;
  top: 0;
}

header.fixed .logopic {
  display: none;
}

header .logopic {
  padding: 20px 0 0;
  width: 245px;
  display: inline-block;
  margin: 0 auto !important;
  float: none !important;
}

header .navi {
  margin: 23px auto 0;
  display: inline-block !important;
  float: none !important;
  position: relative;
}

header.fixed .navi {
  padding-left: 1%;
  margin-top: 10px;
  transform: none;
}

header.fixed .container {
  text-align: left;
}

header .hlogin {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding-left: 28px;
  color: $grey-middle;
  text-transform: uppercase;
  font-family: 'Montserrat', serif;
  @include font-size($fontsize-XS);
  font-weight: 400;
}

header .hlogin:before {
  content: url("assets/images/arden-haendler-login.png");
  display: inline-block;
  position: absolute;
  left: 0;
}

.language-switcher {
  position: absolute;
  right: 140px;
  bottom: 15px;
  width: 60px;
}

.language-switcher ul {
  line-height: 1.2;
}

.wpml-ls-legacy-list-horizontal,
.wpml-ls-legacy-list-horizontal a {
  padding: 0 !important;
}

.wpml-ls-legacy-list-horizontal a span {
  vertical-align: bottom !important;
}

.wpml-ls-legacy-list-horizontal .wpml-ls-current-language a {
  font-weight: bold;
}

.wpml-ls-legacy-list-horizontal .wpml-ls-item:first-child {
  margin-right: 10px;
}

.pagenav-mobile .wpml-ls a {
  color: $white;
}

header .search-toggle {
  position: absolute;
  right: 0;
  bottom: 60px;
}

header.fixed .hlogin {
  right: 50px;
}

header.fixed .search-toggle {
  bottom: 12px;
}

.ovhide {
  overflow: hidden;
}

.overlay {
  overflow: hidden;
}

.search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 200px;
  left: 0;
  background: $black;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.search-overlay.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}

.search-close {
  background: none;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5%;
  top: 5%;
  @include font-size(30);
  overflow: hidden;
  border: none;
  color: $white;
  outline: none;
  z-index: 15;
}

.search-wrapper {
  margin-top: 80px;
  padding: 5%;
}

.search-title {
  display: block;
  @include font-size($fontsize-L2);
  text-transform: uppercase;
  margin-bottom: 20px;
  color: $white;
}

.search-bar input {
  outline: 2px solid $yellow;
  border: none;
  @include font-size($fontsize-M);
  position: relative;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $grey-middle;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey-middle;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $grey-middle;
  opacity: 1 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $grey-middle;
  opacity: 1 !important;
}

.search-form .search-bar:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  left: 30px;
  top: 20px;
  position: absolute;
  z-index: 10;
}

.search-form .search-bar {
  position: relative;
}

#menu-item-193, #menu-item-203, #menu-item-208, #menu-item-3374, #menu-item-3383, #menu-item-3385{
  margin-top: 10px !important;
}

#menu-item-3216 span, #menu-item-193 span, #menu-item-61 span, #menu-item-219 span, #menu-item-226 span{
  line-height: 16.9px !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-family: "Montserrat", serif !important;
  font-weight: 400 !important;
}

@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .language-switcher,
  header .hlogin,
  header .navi,
  header .search-toggle {
    display: none !important;
  }

  header {
    max-height: 160px;
  }

  header.fixed .logo {
    width: 25%;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  header.fixed .logo {
    width: 50%;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
