*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.bee3D--parent {
  -webkit-perspective: 600px;
  perspective: 600px;
}

.bee3D--slide {
  position: absolute;
  -webkit-transform: none;
  transform: none;
  width: 731px;
  height: auto;
  top: 50%;
  left: 50%;
  margin-left: -365px;
  margin-top: -360px;
  opacity: 0;
  transition: opacity 0.7s ease,-webkit-transform 0.7s ease;
  transition: opacity 0.7s ease,transform 0.7s ease;
  transition: opacity 0.7s ease,transform 0.7s ease,-webkit-transform 0.7s ease;
}

.bee3D--slide__inactive {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.bee3D--slide__active {
  opacity: 1;
  z-index: 10;
}

.bee3D--slide__active img {
  outline: 1px solid #efb900;
  outline-offset: -10px;
}

.bee3D--slide img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.bee3D--slide h3 {
  margin: 0 0 5px;
}

.bee3D--slide .black-btn {
  margin-top: 20px;
}

.bee3D--inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.bee3D--inner .textbox-inline {
  padding: 30px 50px;
}

.draggable {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.draggable:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.bee3D--parallax {
  position: relative !important;
}

.bee3D--shadow-wrapper {
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100px;
}

.bee3D--shadow > span {
  display: block;
  content: '';
  left: 0;
  width: 100%;
  height: 200px;
  border-radius: 50%;
  background: rgba(0,0,0,0.45);
  box-shadow: 0 0 200px 50px rgba(0,0,0,0.7);
  transition: opacity 1.5s;
  opacity: 0.15;
  -webkit-transform: rotateX(95deg) translateZ(30px) scale(0.55);
  transform: rotateX(95deg) translateZ(30px) scale(0.55);
}

.bee3D--nav {
  position: absolute;
  cursor: pointer;
  top: 50%;
  font-size: 8em;
  color: rgba(0,0,0,0.45);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.7s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 40px;
}

.bee3D--nav__prev {
  background-image: url("/img/angle-left.svg");
  left: 3%;
}

.bee3D--nav__next {
  background-image: url("/img/angle-right.svg");
  right: 3%;
}

.bee3D--effect__classic .bee3D--before {
  -webkit-transform: translateX(-375%);
  transform: translateX(-375%);
}

.bee3D--effect__classic .bee3D--before-2 {
  -webkit-transform: translateX(-250%);
  transform: translateX(-250%);
  opacity: 0.1;
}

.bee3D--effect__classic .bee3D--before-1 {
  -webkit-transform: translateX(-125%);
  transform: translateX(-125%);
  opacity: 0.3;
}

.bee3D--effect__classic .bee3D--after {
  -webkit-transform: translateX(375%);
  transform: translateX(375%);
}

.bee3D--effect__classic .bee3D--after-2 {
  -webkit-transform: translateX(250%);
  transform: translateX(250%);
  opacity: 0.1;
}

.bee3D--effect__classic .bee3D--after-1 {
  -webkit-transform: translateX(125%);
  transform: translateX(125%);
  opacity: 0.3;
}

.bee3D--effect__carousel .bee3D--before {
  -webkit-transform: translateX(-191.4%) rotateY(-72deg) translateX(-252%);
  transform: translateX(-191.4%) rotateY(-72deg) translateX(-252%);
  opacity: 0;
}

.bee3D--effect__carousel .bee3D--before-2 {
  -webkit-transform: translateX(-590px) rotateY(-52deg) translateX(-152.3%);
  transform: translateX(-590px) rotateY(-52deg) translateX(-152.3%);
  opacity: 0.3;
}

.bee3D--effect__carousel .bee3D--before-1 {
  -webkit-transform: translateX(-52.7%) rotateY(-30deg) translateX(-56.6%);
  transform: translateX(-52.7%) rotateY(-30deg) translateX(-56.6%);
  opacity: 0.5;
}

.bee3D--effect__carousel .bee3D--after {
  -webkit-transform: translateX(191.4%) rotateY(72deg) translateX(252%);
  transform: translateX(191.4%) rotateY(72deg) translateX(252%);
  opacity: 0;
}

.bee3D--effect__carousel .bee3D--after-1 {
  -webkit-transform: translateX(52.7%) rotateY(30deg) translateX(56.6%);
  transform: translateX(52.7%) rotateY(30deg) translateX(56.6%);
  opacity: 0.5;
}

.bee3D--effect__carousel .bee3D--after-2 {
  -webkit-transform: translateX(590px) rotateY(52deg) translateX(152.3%);
  transform: translateX(590px) rotateY(52deg) translateX(152.3%);
  opacity: 0.3;
}

.bee3D--effect__concave .bee3D--before {
  -webkit-transform: translateX(-156%) rotateY(90deg) translateX(-156%);
  transform: translateX(-156%) rotateY(90deg) translateX(-156%);
}

.bee3D--effect__concave .bee3D--before-1 {
  -webkit-transform: translateX(-60.5%) rotateY(28deg) translateX(-46.8%);
  transform: translateX(-60.5%) rotateY(28deg) translateX(-46.8%);
  opacity: 0.4;
}

.bee3D--effect__concave .bee3D--after {
  -webkit-transform: translateX(156%) rotateY(-90deg) translateX(156%);
  transform: translateX(156%) rotateY(-90deg) translateX(156%);
}

.bee3D--effect__concave .bee3D--after-1 {
  -webkit-transform: translateX(60.5%) rotateY(-28deg) translateX(46.8%);
  transform: translateX(60.5%) rotateY(-28deg) translateX(46.8%);
  opacity: 0.4;
}

.bee3D--effect__coverflow .bee3D--before {
  -webkit-transform: translateX(-52.7%) rotateY(45deg) scale(0.6) translateZ(-120px);
  transform: translateX(-52.7%) rotateY(45deg) scale(0.6) translateZ(-120px);
  z-index: 6;
}

.bee3D--effect__coverflow .bee3D--before-3 {
  -webkit-transform: translateX(-45%) rotateY(45deg) scale(0.6) translateZ(-120px);
  transform: translateX(-45%) rotateY(45deg) scale(0.6) translateZ(-120px);
  opacity: 0.1;
  z-index: 7;
}

.bee3D--effect__coverflow .bee3D--before-2 {
  -webkit-transform: translateX(-37.1%) rotateY(45deg) scale(0.6) translateZ(-120px);
  transform: translateX(-37.1%) rotateY(45deg) scale(0.6) translateZ(-120px);
  opacity: 0.2;
  z-index: 8;
}

.bee3D--effect__coverflow .bee3D--before-1 {
  -webkit-transform: translateX(-29.3%) rotateY(45deg) scale(0.6) translateZ(-120px);
  transform: translateX(-29.3%) rotateY(45deg) scale(0.6) translateZ(-120px);
  opacity: 0.3;
  z-index: 9;
}

.bee3D--effect__coverflow .bee3D__active {
  z-index: 10;
}

.bee3D--effect__coverflow .bee3D--after {
  -webkit-transform: translateX(52.7%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  transform: translateX(52.7%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  z-index: 6;
}

.bee3D--effect__coverflow .bee3D--after-1 {
  -webkit-transform: translateX(29.3%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  transform: translateX(29.3%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  opacity: 0.3;
  z-index: 9;
}

.bee3D--effect__coverflow .bee3D--after-2 {
  -webkit-transform: translateX(37.1%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  transform: translateX(37.1%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  opacity: 0.2;
  z-index: 8;
}

.bee3D--effect__coverflow .bee3D--after-3 {
  -webkit-transform: translateX(45%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  transform: translateX(45%) rotateY(-45deg) scale(0.6) translateZ(-120px);
  opacity: 0.1;
  z-index: 7;
}

.bee3D--effect__cube .bee3D--before {
  -webkit-transform: translateX(-64.8%) rotateY(-70deg) translateX(-127.3%) rotateY(-110deg) translateX(-103.5%);
  transform: translateX(-64.8%) rotateY(-70deg) translateX(-127.3%) rotateY(-110deg) translateX(-103.5%);
}

.bee3D--effect__cube .bee3D--before-1 {
  -webkit-transform: translateX(-64.8%) rotateY(-70deg) translateX(-100%);
  transform: translateX(-64.8%) rotateY(-70deg) translateX(-100%);
  opacity: 0.5;
}

.bee3D--effect__cube .bee3D--after {
  -webkit-transform: translateX(64.8%) rotateY(70deg) translateX(127.3%) rotateY(110deg) translateX(103.5%);
  transform: translateX(64.8%) rotateY(70deg) translateX(127.3%) rotateY(110deg) translateX(103.5%);
}

.bee3D--effect__cube .bee3D--after-1 {
  -webkit-transform: translateX(64.8%) rotateY(70deg) translateX(100%);
  transform: translateX(64.8%) rotateY(70deg) translateX(100%);
  opacity: 0.5;
}

.bee3D--effect__arc .bee3D--before {
  -webkit-transform: translate(-146.5%, 63%) rotateZ(-17deg) scale(0.5);
  transform: translate(-146.5%, 63%) rotateZ(-17deg) scale(0.5);
  opacity: 0;
}

.bee3D--effect__arc .bee3D--before-2 {
  -webkit-transform: translate(-109.5%, 42.3%) rotateZ(-14deg) scale(0.6);
  transform: translate(-109.5%, 42.3%) rotateZ(-14deg) scale(0.6);
  opacity: 0.3;
}

.bee3D--effect__arc .bee3D--before-1 {
  -webkit-transform: translate(-67.5%, 19.5%) rotateZ(-8deg) scale(0.8);
  transform: translate(-67.5%, 19.5%) rotateZ(-8deg) scale(0.8);
  opacity: 0.5;
}

.bee3D--effect__arc .bee3D--after {
  -webkit-transform: translate(146.5%, 63%) rotateZ(17deg) scale(0.5);
  transform: translate(146.5%, 63%) rotateZ(17deg) scale(0.5);
  opacity: 0;
}

.bee3D--effect__arc .bee3D--after-1 {
  -webkit-transform: translate(67.5%, 19.5%) rotateZ(8deg) scale(0.8);
  transform: translate(67.5%, 19.5%) rotateZ(8deg) scale(0.8);
  opacity: 0.5;
}

.bee3D--effect__arc .bee3D--after-2 {
  -webkit-transform: translate(109.5%, 42.3%) rotateZ(14deg) scale(0.6);
  transform: translate(109.5%, 42.3%) rotateZ(14deg) scale(0.6);
  opacity: 0.3;
}

.bee3D--effect__wave .bee3D--before {
  -webkit-transform: translate(-134.7%, 31.6%) rotateZ(-6deg) rotateY(-15deg) scale(0.4);
  transform: translate(-134.7%, 31.6%) rotateZ(-6deg) rotateY(-15deg) scale(0.4);
  opacity: 0;
}

.bee3D--effect__wave .bee3D--before-2 {
  -webkit-transform: translate(-109.3%, 26.3%) rotateZ(-14deg) rotateY(-40deg) scale(0.55);
  transform: translate(-109.3%, 26.3%) rotateZ(-14deg) rotateY(-40deg) scale(0.55);
  opacity: 0.3;
}

.bee3D--effect__wave .bee3D--before-1 {
  -webkit-transform: translate(-80%, 14.5%) rotateZ(-11deg) rotateY(-30deg) scale(0.8);
  transform: translate(-80%, 14.5%) rotateZ(-11deg) rotateY(-30deg) scale(0.8);
  opacity: 0.5;
}

.bee3D--effect__wave .bee3D--after {
  -webkit-transform: translate(134.7%, 31.6%) rotateZ(6deg) rotateY(15deg) scale(0.4);
  transform: translate(134.7%, 31.6%) rotateZ(6deg) rotateY(15deg) scale(0.4);
  opacity: 0;
}

.bee3D--effect__wave .bee3D--after-1 {
  -webkit-transform: translate(80%, 14.5%) rotateZ(11deg) rotateY(30deg) scale(0.8);
  transform: translate(80%, 14.5%) rotateZ(11deg) rotateY(30deg) scale(0.8);
  opacity: 0.5;
}

.bee3D--effect__wave .bee3D--after-2 {
  -webkit-transform: translate(109.3%, 26.3%) rotateZ(14deg) rotateY(40deg) scale(0.55);
  transform: translate(109.3%, 26.3%) rotateZ(14deg) rotateY(40deg) scale(0.55);
  opacity: 0.3;
}

.bee3D--effect__spiral-right {
  overflow: hidden;
}

.bee3D--effect__spiral-right .bee3D--before {
  -webkit-transform: translateX(-191.4%) rotateY(-72deg) translate(-252%, 600px);
  transform: translateX(-191.4%) rotateY(-72deg) translate(-252%, 600px);
  opacity: 0;
}

.bee3D--effect__spiral-right .bee3D--before-2 {
  -webkit-transform: translateX(-115.2%) rotateY(-52deg) translate(-152.3%, 400px);
  transform: translateX(-115.2%) rotateY(-52deg) translate(-152.3%, 400px);
  opacity: 0.3;
}

.bee3D--effect__spiral-right .bee3D--before-1 {
  -webkit-transform: translateX(-52.7%) rotateY(-30deg) translate(-56.6%, 200px);
  transform: translateX(-52.7%) rotateY(-30deg) translate(-56.6%, 200px);
  opacity: 0.5;
}

.bee3D--effect__spiral-right .bee3D--after {
  -webkit-transform: translateX(191.4%) rotateY(72deg) translate(252%, -600px);
  transform: translateX(191.4%) rotateY(72deg) translate(252%, -600px);
  opacity: 0;
}

.bee3D--effect__spiral-right .bee3D--after-1 {
  -webkit-transform: translateX(52.7%) rotateY(30deg) translate(56.6%, -200px);
  transform: translateX(52.7%) rotateY(30deg) translate(56.6%, -200px);
  opacity: 0.5;
}

.bee3D--effect__spiral-right .bee3D--after-2 {
  -webkit-transform: translateX(115.2%) rotateY(52deg) translate(152.3%, -400px);
  transform: translateX(115.2%) rotateY(52deg) translate(152.3%, -400px);
  opacity: 0.3;
}

.bee3D--effect__spiral-left {
  overflow: hidden;
}

.bee3D--effect__spiral-left .bee3D--before {
  -webkit-transform: translateX(-191.4%) rotateY(-72deg) translate(-252%, -600px);
  transform: translateX(-191.4%) rotateY(-72deg) translate(-252%, -600px);
  opacity: 0;
}

.bee3D--effect__spiral-left .bee3D--before-2 {
  -webkit-transform: translateX(-115.2%) rotateY(-52deg) translate(-152.3%, -400px);
  transform: translateX(-115.2%) rotateY(-52deg) translate(-152.3%, -400px);
  opacity: 0.3;
}

.bee3D--effect__spiral-left .bee3D--before-1 {
  -webkit-transform: translateX(-52.7%) rotateY(-30deg) translate(-56.6%, -200px);
  transform: translateX(-52.7%) rotateY(-30deg) translate(-56.6%, -200px);
  opacity: 0.5;
}

.bee3D--effect__spiral-left .bee3D--after {
  -webkit-transform: translateX(191.4%) rotateY(72deg) translate(252%, 600px);
  transform: translateX(191.4%) rotateY(72deg) translate(252%, 600px);
  opacity: 0;
}

.bee3D--effect__spiral-left .bee3D--after-1 {
  -webkit-transform: translateX(52.7%) rotateY(30deg) translate(56.6%, 200px);
  transform: translateX(52.7%) rotateY(30deg) translate(56.6%, 200px);
  opacity: 0.5;
}

.bee3D--effect__spiral-left .bee3D--after-2 {
  -webkit-transform: translateX(115.2%) rotateY(52deg) translate(152.3%, 400px);
  transform: translateX(115.2%) rotateY(52deg) translate(152.3%, 400px);
  opacity: 0.3;
}
@media all and (max-width:$size-M2) {
  .bee3D--slide__inactive {
    pointer-events: auto;
  }

  .bee3D--parent {
    width: 100%;
    min-width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .bee3D--slide {
    border-radius: 5px;
    width: 96%;
    padding-left: 2%;
    padding-bottom: 0;
    left: 0%!important;
    margin-left: 0;
  }
}
