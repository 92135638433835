//
// Spacings
//

.pd-30 {
	padding: 30px;
}

.pd-40 {
	padding: 40px;
}

.pd-50 {
	padding: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-100 {
	padding-top: 100px;
}

.ptb-10 {
	padding: 10px 0;
}

.ptb-20 {
	padding: 20px 0;
}

.ptb-40 {
	padding: 40px 0;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-100 {
	padding-bottom: 100px;
}

.ptb-60 {
	padding: 60px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.pl-30 {
	padding-left: 30px;
}

.pl-60 {
	padding-left: 60px;
}

.pr-30 {
	padding-right: 30px;
}
/* Margin Directions */
.mt-10 {
	margin-top: 10px;
}

.mt-30 {
	margin-top: 30px;
}

.mr-10 {
	margin-right: 10px;
}

.mb-10 {
	margin-bottom: 0.5%;
}

.absx,
.absy {
	position: absolute;
}

.absx {
	left: 50%;
	transform: translateX(-50%);
}

.absy {
	top: 50%;
	transform: translateY(-50%);
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
	.pd-40 {
		padding: 30px;
	}

	.pd-50 {
		padding: 40px;
	}
}
@media all and (max-width:$size-XS) {}
