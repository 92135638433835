//
// Headings
//

h1,
h2 {
  color: $grey-dark;
  @include font-size($fontsize-XL);
  font-style: normal;
  font-weight: 400;
  line-height: 3.8rem;
  font-family: 'Crimson Text', Arial, sans-serif;
  text-transform: uppercase;
}

h1 {
  margin: 0 0 30px;
}

h2,
h5 {
  margin: 0 0 20px;
}

h3 {
  font-family: 'Montserrat', Arial, sans-serif;
  color: $grey-dark;
  font-weight: 400;
  @include font-size($fontsize-M);
  line-height: 2.4rem;
  margin-bottom: 30px;
  text-transform: uppercase;
}

h4 {
  font-family: 'Montserrat', Arial, sans-serif;
  color: $grey-dark;
  font-weight: 400;
  @include font-size($fontsize-S2);
  line-height: 2rem;
  margin-bottom: 15px;
  text-transform: uppercase;
}

h5 {
  font-family: 'Montserrat', Arial, sans-serif;
  color: $grey-dark;
  font-weight: 400;
  @include font-size($fontsize-S);
  line-height: 1.8rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}

h6 {
  font-family: 'Montserrat', Arial, sans-serif;
  color: $grey-dark;
  font-weight: 400;
  @include font-size($fontsize-XS);
  line-height: 1.6rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
  h1,
  h2,
  h5 {
    margin: 0 0 10px;
  }

  h1,
  h2 {
    @include font-size($fontsize-L2);
    line-height: 3.4rem;
  }
}
@media all and (max-width:$size-XS) {
  h1,
  h2 {
    @include font-size($fontsize-L);
    line-height: 3rem;
  }
}
