// Text Modules

blockquote {
	outline: 1px solid $white;
	outline-offset: -10px;
	background: $blue-light;
	display: flex !important;
	justify-content: center;
	flex-direction: column;
}

blockquote p {
	font-family: 'Crimson Text', Arial, sans-serif;
	@include font-size($fontsize-L);
	line-height: 2.8rem;
	font-weight: 300;
	text-transform: uppercase;
	font-style: italic;
}

blockquote strong {
	font-family: 'Montserrat',serif;
	@include font-size($fontsize-S2);
	font-weight: 700;
}

.textbox-inline {
	outline: 1px solid $grey-light;
	outline-offset: -10px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.textbox-inline {
	h1,
	h2,
	h3,
	h4 {
		align-self: stretch;
	}

	img {
		width: auto;
	}
}

.textbox-inline h2 + h3 {
	margin: 0 auto 30px;
}

.textbox-inline table,
.textbox-inline ul li {
	color: $grey-dark;
}

.textbox-readmore,
.textbox-readmore-close {
	color: $yellow;
	@include font-size($fontsize-S);
	line-height: 2.4rem;
	margin-top: 5px;
}

.textbox-100 {
	h1,
	h2,
	p,
	ul {
		max-width: 80%;
	}

	p,
	ul {
		text-align: left;
		margin: 0 auto;
	}

	ul {
		padding-left: 15px;
	}

	h1 {
		margin: 0 auto 30px;
	}

	h2 {
		margin: 0 auto 30px;
	}
}

.jbs-2:hover {
	outline: 1px solid $yellow;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.textbox-100 {
		h1,
		h2,
		p,
		ul {
			max-width: 100%;
		}
	}

	.g-5.textbox-inline,
	.g-7.textbox-inline,
	blockquote.g-5 {
		width: 49.6%;
	}
}
@media all and (max-width:$size-L) {
	blockquote.g-5 {
		width: 100%;
	}
}
@media all and (max-width:$size-M2) {
	.g-5.textbox-inline {
		width: 100%;
		order: 2;
	}

	.g-7.textbox-inline {
		width: 49.6%;
	}

	.g-5 + .g-7.textbox-inline,
	blockquote + .g-7.textbox-inline {
		width: 100%;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
	.g-7.textbox-inline {
		width: 100%;
		margin-bottom: 1%;
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
