//
// Main CSS
//

.social-bar {
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 10;
  background: $white;
  padding: 10px 10px 0;
}

.social-bar span {
  padding-bottom: 20px;
  display: block;
}

.social-bar a {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  display: block;
}

.shop-social-bar {
  padding: 12px 8px;

  a {
    display: block;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }

  span {
    padding: 0;
  }
}

.footer-social span,
.social-bar span {
  font-family: FontAwesome;
  text-align: center;
  @include font-size($fontsize-L);
}

.footer-social a {
  display: inline-block;
  margin-left: 10px;
}

.footer-social a:first-child {
  margin-left: 0;
}

header + div {
  padding-top: 200px;
}

.no-gal header + div {
  padding-top: 210px;
}

#wpgmaps_directions_edit_1 {
  background: #fff;
  padding: 40px 40px 10px;
}

#wpgmaps_directions_reset_1 {
  padding: 0 40px;
  background: #fff;
}

#directions_panel_1 {
  padding: 40px;
  background: $white;
}

.wpgmaps_get_directions {
  background: $black;
  padding: 14px 30px 15px;
  color: #fff;
  margin-top: 30px;
  @include font-size($fontsize-S);
  text-transform: uppercase;
  transition: all 0.5s ease;
  display: inline-block;
  border: 0;
}

.wpgmaps_get_directions:hover {
  outline: 1px solid $yellow;
  outline-offset: -10px;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .social-bar {
    display: none;
  }

  header + div {
    padding-top: 160px;
  }

  .no-gal header + div {
    padding-top: 170px;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
@media print {
  body {
    overflow: visible !important;
    background: #fff !important;
  }

  .black-btn,
  .cookie-consent,
  .info-trigger,
  .kfzprint,
  .single-kfzmarkt .imgrid + div,
  .social-bar,
  footer,
  header,
  nav {
    display: none !important;
  }

  .royalSlider.home-top-slider .rsSlide .rsImg {
    width: 100% !important;
    position: relative !important;
    height: auto !important;
  }

  .royalSlider.home-top-slider .rsOverflow {
    width: 100% !important;
    height: 100% !important;
  }

  .slideshow {
    page-break-after: always;
  }

  .fahrzeuginfos,
  .fahrzeuginfos .g-6,
  .text_exposed_show,
  .text_exposed_show p strong {
    position: relative !important;
    page-break-inside: auto !important;
  }

  .container-fullwidth .textbox-inline {
    outline-offset: 0 !important;
    display: block !important;
    padding: 50px 0 !important;
  }

  .fahrzeuginfos,
  .textbox-inline {
    overflow: visible !important;
    float: none !important;
    display: block !important;
    margin-top: 100px !important;
    outline: 0 !important;
    page-break-inside: auto !important;
  }

  .fahrzeuginfos .g-6 {
    width: 100% !important;
  }

  header + div {
    padding-top: 0 !important;
  }

  .imagegrid {
    height: 100% !important;
  }

  .cbp-ready .cbp-item {
    display: block !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    width: 50% !important;
    float: left !important;
    position: relative !important;
    page-break-inside: avoid !important;
  }

  .cbp-ready {
    margin-top: 100px !important;
  }

  .cbp-ready,
  .cbp-ready .cbp-wrapper,
  .cbp-ready .cbp-wrapper-helper {
    position: relative !important;
  }
}
