//
// Global CSS
//
// apply a natural box layout model to all elements, but allowing components to change

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
// ----- HTML-Elemente -----

body {
  margin: 0;
  font-family: $baseFont;
  overflow-x: hidden;
}

html {
  margin-top: 0 !important;
  font-family: $baseFont;
  font-style: normal;
  font-weight: 300;
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

p,
table,
ul {
  color: $grey-dark;
  @include font-size($fontsize-S);
  line-height: 2.4rem;
}
/* Backgrounds Colors */
.wbg {
  background: $white;
}

.dark-theme .textbox-inline.wbg {
  background: $black;
  outline: none;
}

.dark-theme {
  background: $black;
}

.blackbg {
  background: $black;
}
/* Global */
html {
  font-size: 62.5%;
}

body {
  @include font-size($fontsize-S2);
  line-height: 21px;
  line-height: 2.1rem;
  height: 100%;
  color: $grey-dark;
  background-color: $grey-light;
}

a {
  color: $grey-dark;
  text-decoration: none;
}

p,
ul {
  margin: 0 0 25px;
}

p:last-child,
ul:last-child {
  margin-bottom: 0;
}

ul {
  list-style-position: outside;
}

ol {
  list-style-position: outside;
  list-style-type: decimal;
  list-style: none;
}

img {
  border-style: none;
}

.auto-width {
  width: auto !important;
  display: inline-block;
  max-width: none !important;
}
// set some elements responsive

embed,
img,
object,
video {
  height: auto;
  max-width: 100%;
}

.col img {
  width: 100%;
}

.centered {
  text-align: center;
}

.centered p {
  max-width: 90%;
  margin: 0 auto;
}
// and for video

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  @media (max-width: 979px) {
    margin-bottom: 20px;
  }
}

.video embed,
.video iframe,
.video object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.no-margin {
  margin: 0 !important;
}

.right-float {
  float: right !important;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}
/* Helper classes */
/*
 * Image replacement
 */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  /* IE 6/7 fallback */
  *text-indent: -9999px;
}

.ir::before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
}
/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden {
  display: none !important;
  visibility: hidden;
}
/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
  visibility: hidden;
}
/*
 * Clearfix: contain floats
 *
 */
.clearfix::after,
.clearfix::before {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix::after {
  clear: both;
}
/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
  *zoom: 1;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
  .centered p {
    max-width: 100%;
  }
}
@media all and (max-width:$size-XS) {}
