//
// Accordion
//

.dlaccordion h3 {
	background: $black;
	padding: 14px 30px 15px;
	color: $white;
	@include font-size($fontsize-L);
	cursor: pointer;
	margin: 0 0 10px;
	text-align: left;
	position: relative;
	text-transform: uppercase;
	transition: all 0.5s ease;
	font-weight: 300;
}

.dlaccordion h3.ui-state-active,
.dlaccordion h3:focus,
.dlaccordion h3:hover {
	outline: 1px solid $yellow;
	outline-offset: -10px;
}

.dlaccordion h3::after {
	content: "▼";
	@include font-size($fontsize-M2);
	color: $white;
	position: absolute;
	right: 20px;
	top: 15px;
}

.dlaccordion h3:hover::after {
	color: $yellow;
}

.dlaccordion h3.ui-state-active::after {
	content: "▲";
	top: 15px;
	right: 22px;
	color: $yellow;
}

.dlaccordion a::before,
.jobpdf::before {
	width: 47px;
	height: 58px;
	display: block;
	top: -20px;
	left: -20px;
	position: absolute;
}

.dlcontent {
	padding: 0 0 10px;
	text-align: left;
	overflow: hidden;
	background: $white;
}

.media-item {
	margin-bottom: 10px;
}

.media-item:nth-child(2n+1) {
	margin-left: 0;
}

.media-content {
	padding: 20px 20px 0;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.media-content {
		padding: 0 20px;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	.media-item {
		width: 100%;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
	.dlaccordion h3 {
		@include font-size($fontsize-M2);
	}

	.media-content {
		padding: 0;
	}

	.media-content p {
		margin-bottom: 10px;
	}

	.media-item {
		margin-bottom: 20px !important;
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
