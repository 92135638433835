//
// RoyalSlider
//

.rsContent {
  height: 100%;
  position: relative;
  width: 100%;
}

.rsContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.rsSlide {
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.royalSlider.home-top-slider h2 {
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 600;
}

.royalSlider.home-top-slider .rsSlide .rsImg {
  margin-left: 0 !important;
  opacity: 1;
  -moz-transition: opacity 0.35s;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.royalSlider.home-top-slider .rsSlide .hasblock:hover .rsImg {
  opacity: 0.50;
}

.royalSlider.home-top-slider .infoBlock {
  opacity: 0;
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: all 1.25s;
  transition: all 1.25s;
  overflow: hidden;
  position: absolute;
  left: 0;
  text-align: left;
  top: 210px;
  max-width: 55%;
}

.royalSlider.home-top-slider .rsSlide:hover .infoBlock {
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.royalSlider.home-top-slider img.rsImg {
  position: absolute;
}

.royalSlider.home-top-slider {
  max-height: 1200px;
  min-height: 300px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.royalSlider.home-top-slider .container {
  position: relative;
}

.rsLink {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  z-index: 20;
  -webkit-backface-visibility: hidden;
}

.rsArrow {
  width: auto;
}

.rsArrowIcn {
  margin: 0 !important;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .royalSlider.home-top-slider {
    min-height: 220px;
  }
}
@media all and (max-width:$size-XS2) {
  .royalSlider.home-top-slider {
    min-height: 180px;
  }
}
@media all and (max-width:$size-XS) {
  .royalSlider.home-top-slider {
    min-height: 150px;
  }
}
