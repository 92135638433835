//
// Contact Form
//

.wpcf7 input[type="text"],
.wpcf7 input[type="email"],
.wpcf7 select,
.wpcf7 textarea,
.formbox input {
	border: 1px solid $grey-light;
	padding: 1.5rem 2rem;
	margin-bottom: 15px;
	width: 100%;
	color: $grey-dark;
}

.wpcf7 input[type="text"]:focus,
.wpcf7 input[type="email"]:focus,
.wpcf7 select:focus,
.wpcf7 textarea:focus,
.formbox input:focus {
	border: 1px solid $yellow;
}

.wpcf7 input.wpcf7-not-valid {
	border: 1px solid red;
	margin-bottom: 5px;
}

.wpcf7 input.wpcf7-not-valid + span {
	margin-bottom: 15px;
}

.wpcf7 span.wpcf7-list-item {
	margin: 0 1rem 0 0;
}

.wpcf7-validation-errors {
	background: $yellow;
	padding: 2rem !important;
	margin: 1rem 0 0;
	border: 0 !important;
	color: $white;
}

div.wpcf7-mail-sent-ok {
	background: #398f14;
	padding: 2rem;
	margin: 1rem 0 0;
	color: $white;
}

.formbox label {
	float: left;
	margin-bottom: 0.8%;
}

.layout_form {
	margin: 30px auto 0;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {
	.layout_form {
		width: 100%;
	}
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
