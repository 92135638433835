//
// Buttons
//

.black-btn, .cr_button {
	background: $black;
	padding: 14px 30px 15px;
	color: #fff;
	@include font-size($fontsize-S);
	text-transform: uppercase;
	transition: all 0.5s ease;
	display: inline-block;
	border: 0;
}

.btn-switch {
	@include font-size($fontsize-M);
	min-width: 186px;
}

.black-btn:hover, .cr_button:hover {
	outline: 1px solid $yellow;
	outline-offset: -10px;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
	.btn-switch {
		@include font-size($fontsize-M);
		min-width: 160px;
	}
}
@media all and (max-width:$size-XS) {
	.btn-switch {
		@include font-size($fontsize-S);
		min-width: 130px;
	}
}
