//
// Project Color Definitions
//
$grey-light: #eeeeee;
$grey-middle: #999999;
$grey-dark: #333333;
$white: #ffffff;
$black: #000000;
$yellow: #efb900;
$blue-light: #c3d2dd;
$red: #CA0000;
