// Fahrzeugmarkt Module

.fahrzeugmarkt select {
	border: 1px solid $grey-light;
	padding: 1.2rem 4rem 1.2rem 2rem;
	color: $grey-dark;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.fahrzeugmarkt .select-wrapper {
	display: inline-block;
	position: relative;
}

.fahrzeugmarkt .select-wrapper::after {
	content: "▼";
	@include font-size($fontsize-S);
	position: absolute;
	right: 15px;
	top: 15px;
	color: $grey-dark;
	pointer-events: none;
}

.fahrzeugmarkt select:focus {
	border: 1px solid $yellow;
}

.fahrzeuginfos {
	display: none;
	outline: 1px solid $yellow;
	outline-offset: -10px;
	text-align: left;
	overflow: auto;
	position: relative;
}

.fahrzeuginfos h3 {
	margin: 0 0 10px;
}

.fahrzeuginfos ul {
	margin-left: -25px;
}

.fahrzeuginfos tr td:first-child {
	padding-right: 10px;
}

.fahrzeuginfos p {
	margin: 0;
}

.fahrzeugmarkt .g-4,
.fahrzeugmarkt .g-4:first-child {
	margin-bottom: 0.8vw;
}
// .fahrzeugmarkt .g-4:last-child {
// 	margin-left: 0.8%;
// }
// .fahrzeugmarkt .container::after {
// 	content: "";
// 	flex: auto;
// }

.fahrzeugmarkt figure img {
	height: auto;
}

.fahrzeugmarkt .black-linkbtn {
	width: 100%;
	bottom: auto;
	left: auto;
	position: relative;
	background: $black;
	border: 1px solid $yellow;
	padding: 14px 30px 15px;
	color: $white;
	@include font-size($fontsize-S2);
	line-height: 2.8rem;
	text-transform: uppercase;
	transition: all 0.5s ease;
	display: inline-block;
	margin: 0;
	letter-spacing: 0.06rem;
	z-index: 5;
	hyphens: auto;
}

.clmarkt .g-4:last-child {
	margin-left: 0;
}

.clmarkt .g-4 {
	margin-bottom: 0;
}

.clmarkt .container::after {
	display: none;
}

.kfzprint {
	position: absolute;
	right: 25px;
	top: 25px;
	display: inline-block;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS3) {
	.fahrzeuginfos {
		padding: 80px 20px 40px;
		outline-offset: 0;
	}
}
@media all and (max-width:$size-XS) {
	.info-trigger {
		padding: 14px 20px 15px;
	}

	.fahrzeuginfos {
		td {
			width: 100%;
			float: left;
		}

		tr {
			float: left;
			margin-bottom: 10px;
		}

		h3 {
			hyphens: auto;
		}
	}
}
