//
// Veredelung Headlines
//

h1 + h2, h4 + h5 {
	@include font-size($fontsize-M);
	line-height: 1.2;
	font-family: $baseFont;
}

.veredelung h3,
.veredelung h4 {
	@include font-size($fontsize-XL);
	font-style: normal;
	font-weight: 400;
	line-height: 3.8rem;
	font-family: 'Crimson Text', Arial, sans-serif;
	text-transform: uppercase;
	margin-top: 0px;
}

.veredelung figure {
	height: auto;
}

.submenu {
	height: 0;
	opacity: 0;
	padding: 0;
}

.submenu a {
	display: block;
	margin-bottom: 5px;
}

.submenu a:hover {
	color: $yellow;
}

.veredelung .g-6,
.veredelung .g-6:first-child {
	margin-bottom: 0.8vw;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.submenu {
		height: auto !important;
		opacity: 1;
		padding: 30px;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
	.veredelung h3,
	.veredelung h4 {
		@include font-size($fontsize-L2);
		line-height: 3.4rem;
	}
}
@media all and (max-width:$size-XS) {}
