/*
Product Name: Storyline 3D Slider
Product URI: http://www.flasherland.com
Description: Storyline 3D Slider is a stand-out-of-the-crowd product, a perfect slider to display your creative work or just amaze your friends with a new generation slider.
Author: Andrey Boyadzhiev
Author URI: http://flasherland.com
Version: 1.0
*/
html,body {
	margin:0;
	padding:0;
}
.ss-holder, .ss-holder div,.ss-holder h1,.ss-holder h2,.ss-holder h3,.ss-holder h4,.ss-holder h5,.ss-holder h6,.ss-holder form, .ss-holder input, .ss-holder textarea, .ss-holder p{
	margin:0;
	padding:0;
}
.ss-holder * {
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	outline: none;
}
.ss-holder {
	font-family:Open Sans, Geneva, sans-serif;
	font-size:12px;
	color:#8b8b8b;
	font-weight:normal;
	overflow-x:hidden;
	min-height:660px;
}

.ss-holder h2, .ss-holder h3{
	margin-bottom:10px;
}

.forios {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
}
.ss-holder p {
	padding:8px 0 8px 0;
	max-width: 100%;
}

.ss-holder a {
	-webkit-transition: color 0.5s ease;
	-moz-transition: color 0.5s ease;
	-ms-transition: color 0.5s ease;
	-o-transition: color 0.5s ease;
	transition: color 0.5s ease;
	text-decoration: none;
}

/*####################################################*/
/*** ROWS ***/
/*####################################################*/
.ss-holder .ss-row{
    width: 100%;
	float: left;
    position: relative;
	-webkit-backface-visibility: hidden;

}
.ss-holder .ss-full {
    float: left;
    width: 100%;
	text-align:left;
    position: relative;
}
.ss-holder .empty-right {
    float: right;
}
.ss-holder .empty-left {
    float: left;
}
/*####################################################*/
/*** CONTAINER ***/
/*####################################################*/
.ss-holder .content-title {
	/*border-bottom: solid 1px #ccc; */
	margin-bottom: 0px;
	padding-bottom: 14px;
	display: block;
	font-size:30px;
	font-weight: bold;
	line-height:32px;
	color:#83c745;
}

.ss-holder .ss-container {
	background: #fff;
	position: relative;
	margin-left: 0;
	padding:30px 50px;
}

.ss-holder .container-border {
	position: relative;
	z-index: 1;
}
.ss-holder .zindex-up{
	z-index:9;
}

/*####################################################*/
/*** BUTTONS ***/
/*####################################################*/
.ss-holder .button {
	display: inline-block;
	cursor:pointer;
	text-decoration: none;
	font: bold 11px;
	text-transform: uppercase;
	padding: 8px 13px;
	color: #fff;
	font-weight:600;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	text-align: center;
	background:none;

}
.ss-holder .button-s {
	padding: 0px 6px;
}
.ss-holder .button:hover {
	cursor: pointer
}
/*####################################################*/
/*** HOVER EFFECTS ***/
/*####################################################*/
.ss-holder .hover-effect .icon-search, .ss-holder .hover-effect .icon-play, .ss-holder .hover-effect .icon-link {
	position:absolute;
	top:50%;
	left:50%;
	margin-left:-20px;
	margin-top:-20px;
	font-size:23px;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	border:1px solid;
	padding:10px 11px;
	border-radius:50%;
}
.ss-holder .hover-effect .icon-play{
	padding:10px 12px;
}

.ss-holder .clean-img {
	width:100%;
	height:auto;
}
.ss-holder .hover-effect {
	width: 100%;
	height: auto;
	overflow: hidden;
	position:relative;
	text-align: center;
	cursor: default;
	z-index:0;
	-webkit-backface-visibility: hidden;
}
.ss-holder .hover-non .hover-effect {
	width:auto;
	height: auto;
}
.ss-holder .relatedimg{
	height:90px;
	overflow:hidden;
}
.ss-holder #articlehold .hover-effect{
	max-height:290px;
}
.ss-holder #articlehold .no-content .hover-effect{
	max-height:480px;
}
.ss-holder .hover-effect .mask {
	width: 100%;
	height: auto;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
}
.ss-holder img {
	display: block;
	position: relative;
	width:100%;
	height:auto;
}

.ss-holder section.bespoke-active img{
outline: 1px solid $yellow;
outline-offset: -10px;
}


.ss-holder .hover-effect h2 {
	font-size:28px;
	color: #fff;
	text-align: center;
	position: relative;
	padding: 8px;
	padding-top:0px;
	margin: 20px 0 0 0;
}
.ss-holder .hover-effect p {
	font-size: 12px;
	font-weight:600;
	position: relative;
	color: #fff;
	padding: 10px 30px 5px;
	text-align: center;

}
.ss-holder .hover-effect a.info {
	display: inline-block;
	color: #fff;
	text-decoration: none;
}
.ss-holder .hover-effect a.info:hover {
	-webkit-box-shadow: 0 0 5px #000;
	-moz-box-shadow: 0 0 5px #000;
	box-shadow: 0 0 5px #000;
}
.ss-holder .h-style img {
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transition: all 0.7s ease-in-out;
	-moz-transition: all 0.7s ease-in-out;
	-o-transition: all 0.7s ease-in-out;
	-ms-transition: all 0.7s ease-in-out;
	transition: all 0.7s ease-in-out;
}
.ss-holder .h-style .mask {
	height:100%;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	transition: all 0.5s linear;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
}
.ss-holder .h-style h2 {
	font-weight:300;
	margin: 66px 40px 0px 40px;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	color: #FFF;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	transition: all 0.5s linear;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
}
.ss-holder .h-style p , .ss-holder .h-style .ui360 {
	margin-left:20px;
	margin-right:20px;
	color: #fff;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	transition: all 0.5s linear;
}
.ss-holder .h-style a.info{
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	transition: all 0.5s linear;
}
.ss-holder .h-style:hover img {
	-webkit-transform: scale(2);
	-moz-transform: scale(2);
	-o-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}
.ss-holder .h-style:hover .mask {
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}
.ss-holder .h-style:hover h2, .ss-holder .h-style:hover p, .ss-holder .h-style:hover a.info, .ss-holder .h-style:hover .icon-search, .ss-holder .h-style:hover .icon-play, .ss-holder .h-style:hover .icon-link, .ss-holder .h-style:hover .ui360{
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

/*####################################################*/
/*** INFINITY LOADER  ***/
/*####################################################*/
.ss-holder .inifiniteLoader {
    position: fixed;
	width:100px;
    z-index: 100;
    bottom: 42px;
	right:50%;
	visibility:hidden;
	margin-right:-50px;
	-webkit-backface-visibility: hidden;
}

.move-rightl{
	right:96px;
}

/*####################################################*/
/*** OTHER ***/
/*####################################################*/
.ss-holder .icon-soc-container .share-btns {
	padding-top:6px;
	font-size:10px;
	color:#85dbc2;
}
.ss-holder .icon-soc-container .share-btns i{
	font-size:20px;
}

.ss-holder .no-border{
	border:none;
}
.ss-holder #embedvideo{
	width:100%;
	height:290px!important;
	width:100%!important;
}
.ss-holder .embedvideoh{
	height:340px;
}
.ss-holder .addpadding{
	padding-top:60px;
}
.ss-holder .addpaddingmore{
	padding-top:100px;
}

.ss-holder .gcnopadding {
padding: 15px!important;
}
/*####################################################*/
/*** PAGINATION ***/
/*####################################################*/
.ss-holder .page_nav .page-numbers{
	padding:4px 8px;
	margin:0px 0px;
	margin:-2px;
	border:1px solid gray;
	color:#FFB134;
	background:#f7f6f7;
	color:#998896;
}
.ss-holder .page_nav .page-numbers a{
	color:#fff;
}
.ss-holder .page_nav .prev, .page_nav .next{
	border:none;
	color:blue;
}
.ss-holder .pagination {
	font-size:11px;
	line-height:13px;
	text-align:center;
}
.ss-holder .p-position{
	text-align:center;
	position:fixed;
	bottom:36px;
	z-index:4;
	left:50%;
	margin-left:-130px;
	min-width:260px;
}
.ss-holder .pagination span, .ss-holder .pagination a {
	position:relative;
	margin: 2px 2px 2px 0;
	width:auto;
	border:1px solid #ececec;
	display: inline-block;
	color: #fff;
	text-decoration: none!important;
	text-align: center;
	text-shadow: 0 1px 0 rgba(0,0,0,0.2)!important;
	font-size: 11px;
	outline: none!important;
	font-weight: bold!important;
	padding: 6px 9px!important;
	cursor: pointer;
	border-radius: 4px!important;
	-webkit-border-radius: 4px!important;
	-moz-border-radius: 4px!important;
	-o-border-radius: 4px!important;
	border: 1px solid #3a336e!important;
	vertical-align: middle;
	border-color: #69963b!important;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.ss-holder .pagination a:hover{
	color:#fff;
	border: 1px solid #fff!important;
}
.ss-holder .pagination .current{
	padding:11px 14px 10px 14px!important;
	border: 2px solid #fff!important;
}
.ss-holder .numpostpagi{
	color:#fff;
	text-align:center;
	width:100%;
	font-weight:300;
	position:absolute;
	bottom:80px;
	z-index:-1;
}
.ss-holder .numpostinfi{
	font-family:Open Sans, Geneva, sans-serif!important;
	color:#fff!important;
	font-size:10px!important;
	text-align:right;
	right:120px;
	font-weight:400!important;
	position:absolute;
	bottom:43px;
	visibility:hidden;
}
.ss-holder .numpostcontent{
	padding:5px 7px;
	color:#fff!important;
	background:rgba(255,255,255,0.3);
}

/*####################################################*/
/*** SCROLLING EFFECTS MAGIC  ***/
/*####################################################*/
.ss-holder .bespoke-parent{
	width:100%;
	overflow-x:hidden;
	overflow:hidden;
}
.ss-holder article {
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	-ms-perspective: 600px;
	-o-perspective: 600px;
	perspective: 600px;
	position: absolute;
	width:100%;
	min-width:100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow:hidden;
}

.ss-holder section {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
	width: 731px;
	height: auto;
	display:inline-block;
	top: 50%;
	left: 50%;
	margin-left: -365px;
	margin-top: -300px;
	opacity: 0;
	position:absolute;
}

.ss-holder ,
.ss-holder section.bespoke-slide, .ss-holder .ss-row:after, .ss-holder article  {
	-webkit-transition: -webkit-transform .7s ease, opacity .7s ease;
	-moz-transition: -moz-transform .7s ease, opacity .7s ease ;
	-ms-transition: -ms-transform .7s ease, opacity .7s ease;
	-o-transition: -o-transform .7s ease, opacity .7s ease;
	transition: transform .7s ease, opacity .7s ease;
}
.ss-holder section.bespoke-active {
	-webkit-transition: width .7s, height .7s, -webkit-transform .7s, opacity .9s ease;
	-moz-transition: width .7s, height .7s, -moz-transform .7s, opacity .9s ease;
	-o-transition: width .7s, height .7s, -o-transform .7s, opacity .9s ease;
	-ms-transition: width .7s, height .7s, -ms-transform .7s, opacity .9s ease;
	transition: width .7s, height .7s, transform .7s, opacity .9s ease;
	opacity: 1;
	z-index: 1;
}
.ss-holder section.bespoke-inactive .ss-row, .ss-holder section.bespoke-inactive .circle-img, .ss-holder section.bespoke-inactive .tunes{
	pointer-events: none;
}

/*####################################################*/
/*** COVERFLOW THEME ***/
/*####################################################*/
.ss-holder .coverflow section.bespoke-before {
	-webkit-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	z-index: 6;
}
.ss-holder .coverflow section.bespoke-before-3 {
	-webkit-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	transform: translateX(-310px) rotateY(60deg) scale(0.6) translateZ(-100px);
	opacity: 0.1;
	z-index: 7;
}
.ss-holder .coverflow section.bespoke-before-2 {
	-webkit-transform: translateX(-290px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(-290px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(-290px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(-290px) rotateY(60deg) scale(0.6) translateZ(-100px);
	transform: translateX(-290px) rotateY(60deg) scale(0.6) translateZ(-100px);
	opacity: 0.3;
	z-index: 8;
}
.ss-holder .coverflow section.bespoke-before-1 {
	-webkit-transform: translateX(-270px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(-270px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(-270px) rotateY(60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(-270px) rotateY(60deg) scale(0.6) translateZ(-100px);
	transform: translateX(-270px) rotateY(60deg) scale(0.6) translateZ(-100px);
	opacity: 0.5;
	z-index: 9;
}
.ss-holder .coverflow section.bespoke-active {
	z-index: 10;
}
.ss-holder .coverflow section.bespoke-after {
	-webkit-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	z-index:9;
}
.ss-holder .coverflow section.bespoke-after-1 {
	-webkit-transform: translateX(270px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(270px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(270px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(270px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	transform: translateX(270px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	opacity: 0.5;
	z-index: 9;
}
.ss-holder .coverflow section.bespoke-after-2 {
	-webkit-transform: translateX(290px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(290px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(290px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(290px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	transform: translateX(290px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	opacity: 0.3;
	z-index: 8;
}
.ss-holder .coverflow section.bespoke-after-3 {
	-webkit-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-moz-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-ms-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	-o-transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	transform: translateX(310px) rotateY(-60deg) scale(0.6) translateZ(-100px);
	opacity: 0.1;
	z-index: 1;
}

/*####################################################*/
/*** TAGS ***/
/*####################################################*/
.ss-holder .tags{
	padding-top:5px!important;
	padding-bottom:40px!important;
	clear:both;
}
.ss-holder ol.tags li {
	position:static;
	float: left;
	margin-right: 5px;
	font-size: 11px;
	display:inline-block;
}
.ss-holder wol.tags li a {
	-webkit-transition: all 0.5s ease;
	border:1px solid #fff;
	float: left;
	padding: 4px 8px 6px 8px;
	font-weight: normal;
}

/*####################################################*/
/*** NAVIGATION ***/
/*####################################################*/
.ss-holder .navkey{
	cursor:pointer;
	padding:8px;
	background:rgba(255,255,255,0.3);
	color:#fff!important;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
	transition: 1s ease;
}
.ss-holder .navkey:hover{
	background:rgba(255,255,255,0.5);
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
	transition: 1s ease;
}
.ss-holder #enter-arrow, #backb-arrow{
	padding: 8px 9px;
}
.ss-holder .bottom-nav{
	position:fixed;
	bottom:50px;
	right:20px;
	color:#ddd;
	font-size:8px;
}
.ss-nav-arrows-next{
	-webkit-transition: opacity 0.5s ease;
	-moz-transition: opacity 0.5s ease;
	-ms-transition: opacity 0.5s ease;
	-o-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	opacity:0.5;
	z-index:1;
	position:absolute;
	font-size:89px;
	color:#fff;
	right:5%;
	top:50%;
	margin-top:-60px!important;
	cursor: pointer;
}
.ss-nav-arrows-prev{
	-webkit-transition: opacity 0.5s ease;
	-moz-transition: opacity 0.5s ease;
	-ms-transition: opacity 0.5s ease;
	-o-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	opacity:0.5;
	z-index:1;
	position:absolute;
	font-size:89px;
	color:#fff;
	left:5%;
	top:50%;
	margin-top:-60px!important;
	cursor: pointer;
}
.ss-nav-arrows-next:hover, .ss-nav-arrows-prev:hover{

	opacity:1;
}

/*####################################################*/
/*** ANIMATION ELEMENTS ***/
/*####################################################*/
.animated{
	-webkit-animation-fill-mode:both;
	-moz-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	-o-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:1s;
	-moz-animation-duration:1s;
	-ms-animation-duration:1s;
	-o-animation-duration:1s;
	animation-duration:1s;
}
.animated.hinge{
	-webkit-animation-duration:2s;
	-moz-animation-duration:2s;
	-ms-animation-duration:2s;
	-o-animation-duration:2s;
	animation-duration:2s;
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		visibility:hidden;
		-webkit-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		visibility:visible;
		-webkit-transform: translateY(0);
	}
}
@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		visibility:hidden;
		-moz-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		visibility:visible;
		-moz-transform: translateY(0);
	}
}
@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		visibility:hidden;
		-o-transform: translateY(20px);
	}
	100% {
		opacity: 1;
		visibility:visible;
		-o-transform: translateY(0);
	}
}
@keyframes fadeInUp {
	0% {
		opacity: 0;
		visibility:hidden;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		visibility:visible;
		transform: translateY(0);
	}
}
.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
	visibility:visible;
}
@-webkit-keyframes fadeOutDown {
	0% {
		visibility:visible;
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	100% {
		visibility:hidden;
		opacity: 0;
		-webkit-transform: translateY(20px);
	}
}
@-moz-keyframes fadeOutDown {
	0% {
		visibility:visible;
		opacity: 1;
		-moz-transform: translateY(0);
	}
	100% {
		visibility:hidden;
		opacity: 0;
		-moz-transform: translateY(20px);
	}
}
@-o-keyframes fadeOutDown {
	0% {
		visibility:visible;
		opacity: 1;
		-o-transform: translateY(0);
	}
	100% {
		visibility:hidden;
		opacity: 0;
		-o-transform: translateY(20px);
	}
}
@keyframes fadeOutDown {
	0% {
		visibility:visible;
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		visibility:hidden;
		opacity: 0;
		transform: translateY(20px);
	}
}
.fadeOutDown {
	-webkit-animation-name: fadeOutDown;
	-moz-animation-name: fadeOutDown;
	-o-animation-name: fadeOutDown;
	animation-name: fadeOutDown;
}

/*####################################################*/
/*** BUTTONS ***/
/*####################################################*/
.ss-holder .wpb_button {
	margin-bottom: 0;
	margin-top: 0;
	*margin-left: .3em;
	display: inline-block;
	*display: inline;
	text-decoration: none!important;
	text-align: center;
	font-size: 11px;
	outline: none;
	font-weight: bold;
	line-height:normal;
	padding: 9px 13px!important;
	cursor: pointer;
	vertical-align: middle;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.ss-holder .wpb_button:hover {
	color: #333333;
	text-decoration: none;
	background-color: #e6e6e6;
	*background-color: #d9d9d9;
	-webkit-transition: background-color 0.1s linear;
	-moz-transition: background-color 0.1s linear;
	-ms-transition: background-color 0.1s linear;
	-o-transition: background-color 0.1s linear;
	transition: background-color 0.1s linear;
}
.ss-holder .wpb_button {
	border-color: #ccc;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
}
.ss-holder .wpb_defbtn{
	color:#fff;
	background:none;
	border:1px solid #fff;
}
.ss-holder .wpb_defbtn:hover,
.ss-holder .wpb_defbtn:active,
.ss-holder .wpb_defbtn.active,
.ss-holder .wpb_defbtn.disabled,
.ss-holder .wpb_defbtn[disabled]{
	background:none;
	color:#fff;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
/*####################################################*/
/*** RESPONSIVE STYLES ***/
/*####################################################*/

@media (max-width: 530px) {
.ss-holder .bk-list {
	list-style: none;
	position: relative;
	margin-left:10%;
}
.ss-holder .album .hover-effect {
	min-height:100%!important;
	max-height:100%!important;
	width:100%;
	height:100%!important;
}
.ss-holder .tunes div.album span.vinyl div{
	display:none;
}
.ss-holder ul.tunes {
	margin-left:-40px;
}
.ss-holder section.bespoke-inactive .ss-row, .ss-holder section.bespoke-inactive .circle-img, .ss-holder section.bespoke-inactive .tunes  {
	pointer-events: auto;
}
.ss-holder .circle-img{
	position:relative;
	left:50%;
	margin-left:-140px;
	height:290px;
	width:280px;
	top:10px;
}
.ss-holder .circle-img .ch-grid li {
	width: 280px;
	height: 280px;
}
.ss-holder .bespoke-parent {
	overflow:auto;
	overflow-x: hidden;
}
.ss-holder .circle-img .ch-info {
	width: 242px;
	height: 242px;
	margin-top:-1px;
	margin-left:-1px;
}
.ss-holder .ch-item {
	background:380px;
}
.ss-holder .circle-img .ch-info-wrap {
	top: 20px;
	left: 20px;
	width:240px;
	height:240px;
}
.ss-holder .ch-info h3 {
	font-size:16px!important;
	line-height:16px;
	margin: 0 15px;
	margin-left:24px;
	margin-right:24px;
	padding: 46px 0px 10px 0px;
	height: auto;
	border-bottom: dashed 1px #ccc;
}
.ss-holder .ch-info p {
	padding: 10px 5px 0;
	max-height:86px;
	overflow:hidden;
	margin: 0 30px;
	font-size: 12px;
}
.ss-holder .ch-info .empty-left{
	display:none;
}
.ss-holder .circle-img .cell{
	z-index:10;
	top:190px;
	right:48px;
}
.ss-holder .circle-img .ribbon {
	top:95px;
	left:0px;
}
.ss-holder section.bespoke-inactive .ss-row {
	pointer-events: auto;
}
.ss-holder article {
	width:100%;
	min-width:100%;
	overflow:auto;
}
.ss-holder section {
	border-radius: 5px;
	width: 96%;
	padding-left:2%;
	padding-bottom:0px;
	left: 0%!important;
	margin-left:0;
}

.ss-holder .cscrol  {
	padding-right:0px;
	height:100%;
	position:static!important;
	overflow-x:hidden;
	overflow:visible!important;
	overflow-x:hidden;
}
.ss-holder .hidecomm{
	display:none;
}
.bottom-nav{
	display:none;
	visibility:hidden;
}

.ss-holder .numpostinfi{
	visibility:hidden;
	display:none;
}
.ss-holder .user-holder{
	display:none;
}
.ss-holder .ss-row, .ss-full{
	position:static!important;
}

}
