//
// Image Modules
//

figure {
	position: relative;
	overflow: hidden;
	height: 100%;
	text-align: center;
	transition: all 0.5s ease;
	outline: 1px solid transparent;
	outline-offset: -10px;
}

figure img {
	//height: 100%;
	float: left;
	object-fit: cover;
	transition: all 0.5s ease;
}

.imagebox figure img {
	height: 100%;
}

figcaption:hover + a img,
figcaption:hover + img {
	transform: scale(1.4);
}

figure.no-link:hover {
	outline: 1px solid $yellow;
}

.has-submenu figure {
	height: auto;
}

.black-ovbtn {
	background: rgba(0,0,0,0.8);
	border: 1px solid $yellow;
	padding: 14px 30px 15px;
	color: $white;
	@include font-size($fontsize-L);
	line-height: 2.8rem;
	text-transform: uppercase;
	transition: all 0.5s ease;
	display: inline-block;
	position: absolute;
	width: calc(100% - 20px);
	bottom: 10px;
	margin: 0;
	left: 10px;
	max-height: 100px;
	letter-spacing: 0.06rem;
	z-index: 5;
}

.black-ovbtn:hover {
	height: calc(100% - 20px) !important;
	max-height: 100%;
}

.overlay-content {
	position: relative;
	display: block;
}

.overlay-headline {
	display: block;
}

.overlay-subline {
	display: none;
	@include font-size($fontsize-S);
	line-height: 2rem;
	text-transform: uppercase;
	transition: all 0.5s ease;
	margin-top: 10px;
	letter-spacing: 0.2rem;
	color: $yellow;
	animation-name: subfade;
	animation-duration: 0.75s;
}
@-webkit-keyframes subfade {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0.1;
	}

	40% {
		opacity: 0.3;
	}

	60% {
		opacity: 0.5;
	}

	80% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}
@keyframes subfade {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0.1;
	}

	40% {
		opacity: 0.3;
	}

	60% {
		opacity: 0.5;
	}

	80% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}

.black-ovbtn:hover .overlay-content {
	display: inline-block;
	width: 60%;
	@include center;
}

.black-ovbtn:hover .overlay-subline {
	display: inline-block;
}

.bildinhalt .g-3 .no-link .black-ovbtn:hover .overlay-content {
	width: 80%;
}

.bildinhalt .g-3 .no-link .overlay-subline {
	hyphens: auto;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.g-5.imagebox,
	.g-7.imagebox {
		width: 49.6%;
	}

	.bildinhalt .g-3 {
		width: 49.6%;
		margin-bottom: 0.5%;
	}

	.bildinhalt .g-4,
	.quicklinks .g-4 {
		width: 32.8%;
	}

	.overlay-subline {
		white-space: normal;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	.g-7.imagebox {
		width: 100%;
		order: 1;
	}

	.black-ovbtn {
		@include font-size($fontsize-M2);
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
	.bildinhalt .g-3,
	.g-5.imagebox {
		width: 100%;
		margin-bottom: 1%;
	}

	.royalSlider.home-top-slider .rsSlide .rsImg {
		object-fit: cover;
	}

	.imagebox figure img,
	figure img {
		height: 240px;
	}

	.quicklinks .g-4 {
		width: 100%;
	}

	.black-ovbtn {
		@include font-size($fontsize-M2);
		line-height: 2.4rem;
	}
}
// reset for shop images

.shop {
	@media all and (max-width:$size-S) {
		figure img {
			height: auto;
		}
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
