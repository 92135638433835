//
// Stoerer
//

.shop-logoicon {
  max-width: 190px;
  float: left;
  margin-right: 80px;
}

.static-shop h3 {
  margin-bottom: 10px;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .static-shop .g-3,
  .static-shop .g-6 {
    width: 100%;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .static-shop .g-3,
  .static-shop .g-6 {
    text-align: center;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
