//
// Mobile Navigation
//

.mobile-toggle,
.pagenav-mobile {
  display: none;
}

.mobile-toggle {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
}

.mobile-toggle img {
  max-width: 40px;
}

.mm-menu {
  -webkit-font-smoothing: subpixel-antialiased;
}

.mm-menu_theme-black .mm-listitem > a,
.mm-menu_theme-black .mm-navbar > *,
.mm-menu_theme-black .mm-navbar a {
  color: $white;
}

.mm-menu_theme-black .mm-listview .mm-btn_next:after,
.mm-navbar {
  border-color: $yellow;
}

.mm-listitem .mm-btn_next:before,
.mm-listitem:after {
  border-color: rgba(239, 185, 0, 0.5);
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .mobile-toggle {
    display: block;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
