// Text Modules

.video-modul iframe {
	width: 100%;
	min-height: 400px;
	float: left;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.textvid .g-5 {
		width: 41.2%;
	}
}
@media all and (max-width:$size-L) {
	.textvid .g-5,
	.textvid .g-7 {
		width: 49.6%;
	}
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
	.textvid .g-5,
	.textvid .g-7 {
		width: 100%;
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
