/* Wordpress CSS */
/*
 Theme Name: ARDEN 2018
 Description: Custom Theme for www.arden.de
 Version: 1.0
 Author: RaapSteinert Kommunikation GmbH
 Author URI: http://www.raap-steinert.de
 Tags: raapsteinert
 */

// Normalize
@import "base/normalize";
@import "base/webfonts";

// Mixins
@import "base/mixins";

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/headings";
@import "base/grid";
@import "base/spacings";
@import "base/buttons";

// Global CSS
@import "base/global";

// Wordpress Core style
@import "wp/core";
@import "wp/loadmore";

// External Libs
@import "lib/magnific_popup/main";
@import "lib/featherlight/featherlight";
@import "lib/slick_slider/base";
@import "lib/royal_slider/royalslider";
@import "lib/storyline_slider/storyline";
@import "lib/bee3d/bee3d";
@import "lib/cube_grid/cubegrid";
@import "lib/mmenu/mmenu";

// Basic Layout
@import "header";
@import "nav-main";
@import "nav-main-mobile";
@import "main";
@import "footer-rework";
@import "cookie-consent";

// Modules
@import "modules/image";
@import "modules/text";
@import "modules/imagetext";
@import "modules/stoerer";
@import "modules/historie";
@import "modules/accordion";
@import "modules/fahrzeugmarkt";
@import "modules/video";
@import "modules/veredelung";
@import "modules/contact-form";
