//
// Defaul Project Settings
//
// base inner page content width
$maxPageWidth: 1680px;

// base font
$baseFont: 'Montserrat', Arial, sans-serif;

// font sizes
$fontsize-XXL2: 70;
$fontsize-XL: 32;
$fontsize-L2: 28;
$fontsize-L: 24;
$fontsize-M2: 20;
$fontsize-M: 18;
$fontsize-S2: 16;
$fontsize-S: 14;
$fontsize-XS2: 13;
$fontsize-XS: 12;
$fontsize-XXS: 10;

//breakpoints
$size-XXL2: 1920px;
$size-XXL: 1680px;
$size-XL3: 1400px;
$size-XL2: 1260px;
$size-XL1: 1150px;
$size-XL: 1024px;
$size-L3: 950px;
$size-L2: 900px;
$size-L: 850px;
$size-M2: 768px;
$size-M1: 760px;
$size-M: 736px;
$size-S3: 650px;
$size-S2: 600px;
$size-S1: 550px;
$size-S: 480px;
$size-XS3: 440px;
$size-XS2: 380px;
$size-XS: 320px;
