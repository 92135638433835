// Load more

.alm-btn-wrap .alm-load-more-btn {
  background: $black !important;
  padding: 14px 30px 15px 30px !important;
  color: #fff;
  font-size: 1.4rem !important;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: inline-block;
  line-height: normal !important;
  height: auto !important;
  border-radius: 0 !important;
}
