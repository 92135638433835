//
// Historie Module
//

.historie-list {
	list-style: none;
}

.historie-list li {
	margin-right: 10px;
	position: relative;
	display: inline-block;
}

.historie-list li:last-child {
	margin-right: 0;
}

.historie-list .selected {
	outline: 1px solid $yellow;
	outline-offset: -10px;
	padding-bottom: 25px;
}

.historie-list .selected::after {
	@include horizontal-center;
	content: "▾";
	@include font-size($fontsize-M2);
	color: $yellow;
	display: inline-block;
	bottom: 10px;
}

.kfz-historie figure {
	height: auto;
}
//
// Firmenhistorie
//

.cd-horizontal-timeline {
	opacity: 0;
	transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
	/* never visible - this is used in jQuery to check the current MQ */
	content: 'mobile';
	display: none;
}

.cd-horizontal-timeline.loaded {
	/* show the timeline after events position has been set (using JavaScript) */
	opacity: 1;
}

.cd-horizontal-timeline .timeyline {
	position: relative;
	height: 100px;
	width: 100%;
	margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
	/* these are used to create a shadow effect at the sides of the timeline */
	content: '';
	position: absolute;
	z-index: 2;
	top: 0;
	height: 100%;
	width: 20px;
}

.cd-horizontal-timeline .events {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 49px;
	height: 2px;
	background: #dfdfdf;
	transition: transform 0.4s;
}

.cd-horizontal-timeline .filling-line {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: $yellow;
	transform: scaleX(0);
	transform-origin: left center;
	transition: transform 0.3s;
}

.cd-horizontal-timeline .events a {
	position: absolute;
	bottom: -23px;
	z-index: 2;
	text-align: center;
	@include font-size($fontsize-L2);
	padding: 15px 50px;
	transform: translateZ(0);
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #ec7404;
	border-color: #ec7404;
}

.cd-horizontal-timeline .events a.selected {
	pointer-events: none;
	outline: 1px solid $yellow;
	outline-offset: -10px;
}

.cd-horizontal-timeline .events a.selected::after {
	background-color: #ec7404;
	border-color: #ec7404;
}

.cd-horizontal-timeline .events a.older-event::after {
	border-color: #ec7404;
}
@media only screen and (min-width: 1100px) {
	.cd-horizontal-timeline::before {
		content: 'desktop';
	}
}

.cd-timeline-navigation {
	list-style: none;
	display: none;
}

.cd-timeline-navigation a {
	/* these are the left/right arrows to navigate the timeline */
	position: absolute;
	z-index: 1;
	top: 50%;
	bottom: auto;
	transform: translateY(-50%);
	height: 34px;
	width: 34px;
	border-radius: 50%;
	border: 2px solid #dfdfdf;
	/* replace text with an icon */
	overflow: hidden;
	color: transparent;
	text-indent: 100%;
	white-space: nowrap;
	transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
	/* arrow icon */
	content: '';
	position: absolute;
	height: 16px;
	width: 16px;
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	transform: translateX(-50%) translateY(-50%);
	background: url("assets/images/cbp-sprite.png") no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
	left: 5px;
	transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
	right: 5px;
}

.no-touch .cd-timeline-navigation a:hover {
	border-color: #ec7404;
}

.cd-timeline-navigation a.inactive {
	cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
	background-position: -131px -24px;
	opacity: 0.3;
}

.cd-timeline-navigation a.next::after {
	background-position: -131px -24px;
}

.cd-timeline-navigation a.prev::after {
	background-position: -131px -24px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
	border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
	position: relative;
	width: 100%;
	overflow: hidden;
	transition: height 0.4s;
}

.cd-horizontal-timeline .events-content ol {
	list-style: none;
	padding: 0;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
}

.cd-horizontal-timeline .events-content li {
	position: absolute;
	z-index: 1;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 20px;
	left: 0;
	top: 0;
	transform: translateX(-100%);
	box-sizing: border-box;
	opacity: 0;
	animation-duration: 0.4s;
	animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline .events-content li.selected {
	/* visible event content */
	position: relative;
	z-index: 2;
	opacity: 1;
	transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
	animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
	animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-left,
.cd-horizontal-timeline .events-content li.leave-right {
	animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li > * {
	max-width: 100%;
	margin: 0 auto;
}

.cd-horizontal-timeline .events-content h2 {
	margin: 0 0 30px;
}

.cd-horizontal-timeline .events-content em {
	display: block;
	font-style: italic;
	margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
	content: '- ';
}
@media only screen and (min-width: 768px) {
	.cd-horizontal-timeline .events-content h2 {
		@include font-size($fontsize-XXL2);
	}

	.cd-horizontal-timeline .events-content em {
		@include font-size($fontsize-M2);
	}
}
@-webkit-keyframes cd-enter-right {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
@-moz-keyframes cd-enter-right {
	0% {
		opacity: 0;
		-moz-transform: translateX(100%);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0%);
	}
}
@keyframes cd-enter-right {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
@-webkit-keyframes cd-enter-left {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
@-moz-keyframes cd-enter-left {
	0% {
		opacity: 0;
		-moz-transform: translateX(-100%);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0%);
	}
}
@keyframes cd-enter-left {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.events-content figure.g-5 {
		width: 49.6%;
	}

	.cd-timeline-navigation {
		display: block;
	}

	.cd-horizontal-timeline .timeyline {
		padding: 0 50px;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	.kfz-historie .g-7.imagebox {
		order: 1;
	}

	.kfz-historie .g-5.textbox-inline {
		order: 2;
	}

	.events-content .g-7.textbox-inline {
		width: 100%;
		order: 2;
	}

	.events-content figure.g-5 {
		width: 100%;
		order: 1;
	}

	.cd-horizontal-timeline .events a {
		font-size: 2rem;
		padding: 15px 30px;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
