//
// Cookie Consent CSS
//

.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 20px;
  color: $white;
  background: $black;
  overflow: hidden;
  z-index: 999;
  text-align: center;
  //display: none;
  @include font-size(15);

  p {
    margin: 0;
    padding: 5px 0;
    display: inline-block;
    line-height: 1.2;
  }

  a {
    color: $white;
    text-decoration: underline;
    @include font-size(15);
  }

  button {
    display: inline-block;
    margin-left: 10px;
    border: 0;
    padding: 5px 20px;
    background: $yellow;
    color: $white;
    font-weight: 700;
  }
}

body.cookie-consent-note-on {

}
