//
// Footer
//

.prefooter {
  outline: 1px solid $grey-light;
  outline-offset: -10px;
}

.logoicon {
  max-width: 125px;
}

footer ul {
  list-style: none;
}

footer .blackbg {
  border-top: 2px solid $yellow;
  position: relative;
  margin-top: -10px;
  z-index: 5;
}

footer .blackbg a,
footer .blackbg p {
  color: $white;
  @include font-size($fontsize-XXS);
  text-transform: uppercase;
}

footer li {
  margin-bottom: 10px;
  @include font-size($fontsize-S);
}

.footer-text {
  left: 10px;
  position: relative;
}

.footer-text p {
  @include font-size($fontsize-XS2);
}

.footer-text h5 {
  color: $grey-dark;
  @include font-size($fontsize-L);
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  font-family: 'Crimson Text', Arial, sans-serif;
  text-transform: uppercase;
}

.footer-zahlungsarten {
  padding-left: 317px;
}

.footer-logo {
  flex: 0.5;
  padding: 0 0 0 80px;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.footer-text {
  flex: 2;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.footer-links {
  flex: 1;
  border-left: 1px solid #eee;
  top: 10px;
  position: relative;
}

.footer-impressum a {
  margin-right: 5px;
}

.footer-impressum a:last-child {
  margin-right: 0;
}

footer .pagenav li {
  display: block;
  float: none;
  text-align: left;
  margin: 0 0 10px;
}

footer .pagenav li a {
  @include font-size($fontsize-S);
}

footer .pagenavli a:hover::after {
  display: none;
}

.footer-social {
  padding-top: 5px;
}

.footer-social a {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.nav-footer {
  a {
    text-transform: uppercase;
    text-decoration: none;
    color: $grey-dark;
  }
}

.footer-subline .col {
  width: 33%;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .footer-subline .col:first-child {
    padding-left: 15px;
  }

  .footer-subline .col:last-child {
    padding-right: 15px;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .footer-logo {
    padding: 0 0 0 40px;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  .prefooter {
    flex-direction: column;
  }

  .footer-logo {
    padding: 40px 0 0;
    display: block !important;
    width: 100%;
    text-align: center;
  }

  footer .pagenav li {
    float: left;
    margin: 0 10px 10px;
    text-align: center;
  }

  .footer-text {
    left: auto;
  }

  .footer-links {
    padding: 0 20px 40px;
  }

  .footer-subline {
    padding: 0 20px;
  }
}
@media all and (max-width:$size-S) {
  .footer-social {
    display: none !important;
  }

  .footer-subline .col {
    width: 50%;
  }
}
@media all and (max-width:$size-XS2) {
  .footer-subline .col {
    width: 100%;
    text-align: center;
  }

  .footer-subline .col:first-child {
    padding-left: 0;
  }

  .footer-subline .col:last-child {
    padding-right: 0;
  }

  .footer-subline .col:first-child {
    margin-bottom: 10px;
  }
}
@media all and (max-width:$size-XS) {}
