//
// Main Navigation
//

nav {
  position: relative;
}

.pagenav ul {
  list-style: none;
  padding: 0;
}

.pagenav > ul {
  display: table;
}

.pagenav li {
  display: block;
  float: left;
  margin-right: 30px;
  text-align: center;
  position: relative;
}

.pagenav a {
  color: $grey-dark;
  display: block;
  font-family: 'Montserrat', serif;
  @include font-size($fontsize-XS2);
  font-weight: 400;
  transition: all 0.25s ease;
  position: relative;
  text-transform: uppercase;
}

.pagenav .ubermenu-current-menu-ancestor > a::before,
.pagenav .ubermenu-current-menu-item.ubermenu-has-submenu-stack > a::before,
.pagenav .ubermenu-item.ubermenu-item-level-0 > a:hover::before,
.ubermenu-item-level-0.ubermenu-current-menu-item > a::before,
.ubermenu-item-level-0.ubermenu-current-page-parent > a::before {
  background: $yellow;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: -2px;
  content: "";
  display: block;
}

.ubermenu-item-level-0.no-ancestor > a::before {
  display: none;
}

header.fixed .pagenav .ubermenu-item.ubermenu-item-level-0 > a:hover::before,
header.fixed .ubermenu-current-menu-item > a::before {
  bottom: -2px;
}

.pagenav ul ul a:hover {
  color: $yellow !important;
}

header.fixed .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  margin-top: 2px !important;
}

.ubermenu-item-level-0 > .ubermenu-submenu-drop {
  border: 0;
  margin-top: 2px !important;
  box-shadow: none;
}

.ubermenu-submenu-drop a span {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.pagenav .sub-menu a:hover::after {
  display: none;
}

.pagenav .sub-menu {
  display: none;
  position: absolute;
  z-index: 110;
  padding-top: 13px;
}

.pagenav .sub-menu li {
  background: rgba(0, 0, 0, 0.8);
  display: block;
  position: relative;
}

.pagenav .sub-menu li:first-child a {
  border-top: 2px solid $yellow;
}

.pagenav .sub-menu a {
  border-top: 1px solid #444;
  color: #999998;
  display: block;
  @include font-size($fontsize-S2);
  padding: 12px 20px;
  text-align: left;
  width: 230px;
}

.pagenav .sub-menu a:hover {
  color: $white;
}

.pagenav .sub-menu .menu-item-has-children::after {
  width: 16px;
  height: 9px;
  right: -25px;
  top: 40%;
  content: ">";
  color: $grey-middle;
  display: inline-block;
  position: absolute;
}

.pagenav .sub-menu .menu-item-has-children {
  position: relative;
}

.pagenav .sub-menu .menu-item-has-children::after {
  content: ">";
  color: $grey-middle;
  position: absolute;
  right: 25px;
  top: 14px;
  width: 4px;
  height: 10px;
}

.pagenav .sub-menu .menu-item-has-children:hover:after {
  color: $white;
}

.pagenav .menu-item-has-children:hover > .sub-menu {
  display: block;
}

.pagenav .sub-menu .sub-menu {
  display: none;
  position: absolute;
  left: 100%;
  margin-top: 0;
  top: 0;
  width: 180px;
  padding-top: 0;
  padding-left: 2px;
}

.pagenav .sub-menu .sub-menu li {
  border-top: none;
  float: left;
}

.pagenav .sub-menu .sub-menu li:first-child a {
  border-top: 2px solid $yellow !important;
}

.pagenav .sub-menu .sub-menu a {
  border-top: 1px solid #4d4d4d !important;
  @include font-size($fontsize-XS);
  width: 180px;
}

.pagenav .sub-menu .sub-menu .sub-menu {
  width: auto;
}

.pagenav .sub-menu .sub-menu .sub-menu li {
  float: none;
}

.pagenav .sub-menu .sub-menu .sub-menu a {
  color: #999998;
}

.pagenav .sub-menu .sub-menu .sub-menu a:hover {
  color: #fff;
}

.pagenav .ubermenu .ubermenu-submenu-type-stack {
  padding-top: 0;
  padding-bottom: 0;
}

.pagenav .ubermenu-submenu-padded {
  padding: 5px 10px !important;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target {
  padding-top: 15px !important;
}

.ubermenu .ubermenu-submenu-padded li:first-child .ubermenu-target {
  padding-top: 0 !important;
}

.ubermenu .ubermenu-submenu-padded li:last-child .ubermenu-target {
  padding-bottom: 0 !important;
}

.pagenav .ubermenu .ubermenu-submenu-padded li .ubermenu-target {
  padding-top: 0 !important;
}

.pagenav .ubermenu .ubermenu-submenu-padded {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pagenav li.ubermenu-current-menu-item a {
  color: $yellow !important;
}

.pagenav li.ubermenu-current-menu-item ul a,
.pagenav li.ubermenu-current-menu-item ul ul a {
  color: $black !important;
}

.pagenav li.ubermenu-current-menu-item ul ul a:hover {
  color: $yellow !important;
}

.pagenav .ubermenu-current-menu-item.ubermenu-has-submenu-stack > a {
  color: $black !important;
}

#menu-item-314 li a {
  font-weight: normal !important;
}
//.ubermenu-skin-minimal .ubermenu-submenu .ubermenu-item-header > .ubermenu-target {
//  font-weight: normal !important;
// }

#menulink {
  position: fixed;
  top: 25px;
  right: 25px;
  color: #000 !important;
  @include font-size(35);
  white-space: nowrap;
  line-height: 1;
  display: none;
}
// Magento Exclusive

.shop {
  .pagenav {
    nav {
      // hide sub levels
      ul ul {
        display: none;
      }
    }
  }
}
