//
// Base Grid
//

.container {
  margin: 0 auto;
  max-width: 1260px;
  width: 100%;
  position: relative;
}

.shop .container {
  max-width: none;

  @media screen and (min-width:$size-XXL) {
    max-width: 1680px;
  }
}

.container-shop {
  display: inline-block;
  text-align: left;
  position: relative;
}

.container.centered p {
  margin: 0 auto;
  text-align: center;
}

.container-fullwidth::after {
  clear: both;
  content: " ";
  display: table;
}

.container_fullwidth {
  width: 100%;
  position: relative;
}

.container .col {
  display: block;
  float: left;
  box-sizing: border-box;
}

.container .col:first-child {
  margin: 0;
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row wrap;

  .mb-10,
  .mb-10:first-child {
    margin-bottom: 0.8vw;
  }
}

.flex-center {
  justify-content: center;
}

.container.flex-row {
  flex-direction: row;
}

.g-1 {
  width: 7.6%;
}

.g-2 {
  width: 16%;
}

.g-3 {
  width: 24.4%;
}

.g-4 {
  width: 32.8%;
}

.g-4:nth-child(3n+1) {
  margin-left: 0;
}

.qlink {
  width: 32.8333%;
}

.g-5 {
  width: 41.2%;
}

.g-50 {
  width: 50%;
}

.g-6 {
  width: 49.6%;
}

.g-7 {
  width: 58%;
}

.g-8 {
  width: 66.4%;
}

.g-9 {
  width: 74.8%;
}

.g-10 {
  width: 83.2%;
}

.g-11 {
  width: 91.6%;
}

.g-12 {
  width: 100%;
}

.pl-1 {
  padding-left: 7.6%;
}

.pl-2 {
  padding-left: 16%;
}

.pl-3 {
  padding-left: 24.4%;
}

.pl-4 {
  padding-left: 32.8%;
}

.pl-5 {
  padding-left: 41.2%;
}

.pl-6 {
  padding-left: 49.6%;
}

.pl-7 {
  padding-left: 58%;
}

.pl-8 {
  padding-left: 66.4%;
}

.pl-9 {
  padding-left: 74.8%;
}

.pl-10 {
  padding-left: 83.2%;
}

.pl-11 {
  padding-left: 91.6%;
}

.pr-1 {
  padding-right: 7.6%;
}

.pr-2 {
  padding-right: 16%;
}

.pr-3 {
  padding-right: 24.4%;
}

.pr-4 {
  padding-right: 32.8%;
}

.pr-5 {
  padding-right: 41.2%;
}

.pr-6 {
  padding-right: 49.6%;
}

.pr-7 {
  padding-right: 58%;
}

.pr-8 {
  padding-right: 66.4%;
}

.pr-9 {
  padding-right: 74.8%;
}

.pr-10 {
  padding-right: 83.2%;
}

.pr-11 {
  padding-right: 91.6%;
}
@media all and (min-width:$size-XXL) {
  .container {
    max-width: 1680px;
  }
}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .g-4 {
    width: 49.6%;
  }

  .g-4:nth-child(3n+1) {
    margin-left: 0.8%;
  }

  .g-4:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .g-4,
  .g-5,
  .g-6,
  .g-7 {
    width: 100%;
  }

  .container.flex-row {
    flex-direction: column;
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}


// Shop Grid

.shop {

  .g-3 {
    @media screen and (max-width:$size-XL3) {
      width: 49.6%;
    }
  }

  @media screen and (max-width:$size-S3) {
    .g-3,
    .g-6 {
      width: 100%;
      margin-bottom: 10px;
    }
  }

}